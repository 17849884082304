<template transition="scale-transition" origin="center center">
  <v-container id="advance" fluid fill-height class="ml-4">
    <v-row justify="center">
      <v-col cols="12" class="pt-0">
        <DataTable
          :headers="headers"
          :url="url"
          secondary
          :urlCount="urlCount"
          :title="this.$t('views.settings.advance')"
          :height="650"
          updateOn
          name="Advance"
        >
          <template #[`item.status`]="{ item }">
            <v-switch
              v-model="item.status"
              hide-details

              class="my-0"
              dense
              @change="changeStatus(item)"
            />
          </template>
        </DataTable>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Advance",
  metaInfo() {
    return {
      title: this.$t("views.settings.advance"),
    };
  },
  mounted() {

    if (!this.token) {
      window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE;
    }

    if (this.typeUser == 2) {
      this.$router.push({ name: "Error" });
    }

    setTimeout(() => {
      this.showLoader({ show: false });
    }, 1000);
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      token: "auth/token",
    }),
    idCompany() {
      let userMap = JSON.parse(this.user);
      return userMap.user.idCompany;
    },
    url() {
      return "/PaymentAdvance/ListTrakingOptionsAsync";
    },
    urlCount() {
      return "/PaymentAdvance/ListTrakingOptionsCountAsync";
    },
    headers() {
      return [
        {
          text: this.$t("components.views.settings.advance.status"),
          class: "text-center",
          value: "status",
          sortable: false,
          width: "15%",
        },
        {
          text: this.$t("components.views.settings.advance.type_operation"),
          class: "text-center",
          value: "description",
          align: "text-capitalize",
        },
      ];
    },
  },
  watch: {
    token(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.token) {
          window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE;
        }
      }
    },
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    ...mapActions("loader", ["showLoader"]),

    async changeStatus(item) {
      let idTypeOperation = item.idTrakingOption,
        descriptionTypeOperation = item.description;

      //Activar el estado
      if (item.status) {
        let params = { idTypeOperation, descriptionTypeOperation };
        await this.axios
          .post(`/PaymentAdvance/Create`, params)
          .then(() => {
            this.showSnack({
              text: this.$t("components.views.settings.advance.enabled_status_success"),
              title: this.$t("components.views.settings.advance.success"),
              name: "success",
            });
          })
          .catch((error) => {
            this.showSnack({
              text: error.response.data,
              title: this.$t("components.views.settings.advance.enabled_status_error"),
              name: "error",
            });
          });
      } else {
        //Desactivar el Estado
        await this.axios
          .delete(
            `/PaymentAdvance/Delete?idCompany=${this.idCompany}&idTypeOperation=${idTypeOperation}`
          )
          .then(() => {
            this.showSnack({
              text: this.$t("components.views.settings.advance.disabled_status_success"),
              title: this.$t("components.views.settings.advance.success"),
              name: "success",
            });
          })
          .catch((error) => {
            this.showSnack({
              text: error.response.data,
              title: this.$t("components.views.settings.advance.disabled_status_error"),
              name: "error",
            });
          });
      }
    },
  },
};
</script>
